import type { NextPage } from "next";
import Link from "next/link";
import { useMemo, type CSSProperties } from "react";
import { useTranslation } from 'next-i18next';
import { Button } from "@mui/material";

export type FooterType = {
  className?: string;
  image6?: string;
  image7?: string;
  vecteezySnapchatLogoPngSn?: string;
  vecteezyViberLogoPngViber?: string;
  image8?: string;
  image9?: string;
  image10?: string;
  fanpagePlaceholder?: string;

  /** Style props */
  propMarginTop?: CSSProperties["marginTop"];
};

const Footer: NextPage<FooterType> = ({
  className = "",
  image6,
  image7,
  vecteezySnapchatLogoPngSn,
  vecteezyViberLogoPngViber,
  image8,
  image9,
  image10,
  fanpagePlaceholder,
  propMarginTop,
}) => {
  const footerStyle: CSSProperties = useMemo(() => {
    return {
      marginTop: propMarginTop,
    };
  }, [propMarginTop]);

  const { t } = useTranslation();

  return (
    <footer
      className={`self-stretch bg-primary flex flex-row items-start justify-center py-5 px-60 box-border gap-[48px] max-w-full text-left text-sm text-white font-headline mq850:pl-[60px] mq850:pr-[60px] mq850:box-border mq1225:pl-[120px] mq1225:pr-[120px] mq1225:box-border mq1225:flex-wrap ${className}`}
      style={footerStyle}
    >
      <div className="w-[525px] flex flex-col items-center justify-start gap-[12px] max-w-full">
        <div className="self-stretch flex flex-row items-center justify-start gap-[8px] max-w-full text-base text-darkslateblue mq450:flex-wrap">
          <div className="flex flex-row items-center justify-start gap-[4px]">
            <img
              className="h-6 w-[28.5px] relative"
              alt=""
              src="/frame-2.svg"
            />
            <div className="w-[57px] relative font-semibold hidden">S-pack</div>
          </div>
          <h2 className="m-0 flex-1 relative text-5xl font-medium font-inherit text-white inline-block min-w-[243px] max-w-full mq450:text-lgi">
            {t('contact.company_name')}
          </h2>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start py-0 px-0 box-border gap-[4px] max-w-full">
          <div className="w-[545.4px] flex flex-row items-center justify-start gap-[8px] max-w-[133%] shrink-0">
            <img
              className="h-4 w-4 relative overflow-hidden shrink-0"
              alt=""
              src="/pin-drop-1.svg"
            />
            <div className="flex-1 relative font-medium inline-block max-w-[calc(100%_-_24px)]">
              {t('footer.address')}
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start py-0 px-6 box-border max-w-full">
            <div className="flex-1 relative font-light inline-block max-w-full">
              <p className="m-0 mb-[8px]">
                {t('contact.address_hanoi')}
              </p>
              <p className="m-0">
                {t('contact.address_hcm')}
              </p>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start py-0 px-0 box-border gap-[4px] max-w-full">
          <div className="w-[545.4px] flex flex-row items-center justify-start gap-[8px] max-w-[133%] shrink-0">
            <img
              className="h-4 w-4 relative overflow-hidden shrink-0"
              alt=""
              src="/mail.svg"
            />
            <div className="flex-1 relative font-medium inline-block max-w-[calc(100%_-_24px)]">
              {t('footer.email')}
            </div>
          </div>
          <div className="w-[569.4px] flex flex-row items-start justify-start py-0 px-6 box-border max-w-[139%] shrink-0">
            <div className="flex-1 relative font-light inline-block whitespace-nowrap max-w-full">
              info@spackplastic.com
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start py-0 px-0 box-border gap-[4px] max-w-full">
          <div className="w-[545.4px] flex flex-row items-center justify-start gap-[8px] max-w-[133%] shrink-0">
            <img
              className="h-4 w-4 relative overflow-hidden shrink-0"
              alt=""
              src="/phone-android.svg"
            />
            <div className="flex-1 relative font-medium inline-block max-w-[calc(100%_-_24px)]">
              {t('footer.phone')}
            </div>
          </div>
          <div className="w-[569.4px] flex flex-row items-start justify-start py-0 px-6 box-border max-w-[139%] shrink-0">
            <div className="flex-1 relative font-light inline-block max-w-full">
              <p className="m-0">{t('contact.phone_number.hanoi')}</p>
            </div>
          </div>
        </div>
        <div className="self-stretch relative font-light">
          <p className="m-0">{t('footer.business_registration')}</p>
          <p className="m-0">{t('footer.business_registration_place')}</p>
        </div>
        <div className="flex flex-row items-center justify-start py-0 w-full pl-0 gap-[12px] mq850:flex-wrap mq450:pr-5 mq450:box-border">
          <img
            className="h-[60px] w-[61px] relative object-cover"
            loading="lazy"
            alt=""
            src={image6}
          />
          <img
            className="h-[50px] w-[132px] relative object-contain"
            loading="lazy"
            alt=""
            src={image7}
          />
        </div>
      </div>

      <div className="w-[525px] flex flex-col items-center justify-end gap-[24px] max-w-full text-5xl">
        <div className="self-stretch flex flex-col items-center justify-start gap-[12px]">
          <h2 className="m-0 self-stretch relative text-inherit font-medium font-inherit mq450:text-lgi">
            {t('footer.social_title')}
          </h2>
          <div className="self-stretch flex flex-row items-start justify-start py-0 pl-0 gap-[12px] text-xs mq850:flex-wrap mq850:box-border">
            <Link href="https://wa.me/84961388969" target="_blank">
              <img
                className="h-8 w-8 relative object-cover"
                loading="lazy"
                alt=""
                src={vecteezySnapchatLogoPngSn}
              />
            </Link>
            <Link href={"skype:live:huonglan90?chat"}>
              <img
                className="h-8 w-8 relative object-cover"
                loading="lazy"
                alt=""
                src={vecteezyViberLogoPngViber}
              />
            </Link>

          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
