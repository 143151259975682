import React, { } from "react";
import Link from "next/link";
import data from "../../data/navigation.json";
import { useTranslation } from 'next-i18next';
import { Category } from "./header";

interface NavigationBarProps {
  customClass?: string;
  linkText?: string;
  categories?: Category[];
  cate?: string;
}

interface MenuItem {
  id: number;
  translationKey: string;
  link?: string;
  child?: boolean;
  icon?: string;
  submenu?: {
    id: number;
    link: string;
    translationKey: string;
  }[];
}

const NavigationBar: React.FC<NavigationBarProps> = ({ customClass, linkText, categories, cate }) => {
  const { t } = useTranslation('common');


  return (
    <>
      <div className={`newspark-header-main-menu ${customClass}`}>
        {data?.data?.length > 0 && (
          <ul>
            {data.data.map((item: MenuItem) => (
              <li key={item.id}>
                {item.child ? (
                  <>
                    <Link
                      href="#"
                      style={{
                        textDecoration: "none",
                        color: linkText === t(item.translationKey) ? "#2A77EE" : undefined,
                      }}
                    >
                      {t(item.translationKey)}
                      <i className="fal fa-angle-down"></i>
                    </Link>
                  </>
                ) : (
                  <Link
                    href={item.link || "#"}
                    style={{
                      textDecoration: "none",
                      color: linkText === t(item.translationKey) ? "#2A77EE" : undefined,
                    }}
                  >
                    {t(item.translationKey)}
                  </Link>
                )}
                {item.child && categories && (
                  <ul className="sub-menu">
                    {categories?.length > 0 &&
                      categories.map((category) => (
                        <li key={category.id}>
                          <Link
                            href={{
                              pathname: `/${category.slug}`,
                              query: { cat: category.id },
                            }}
                            style={{
                              textDecoration: "none",
                              color: cate == (category.id + "") ? "#2A77EE" : undefined,
                            }}
                          >
                            {category.title}
                          </Link>
                        </li>
                      ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default NavigationBar;
