import type { NextPage } from "next";
import { Button, Hidden, TextField } from "@mui/material";
import NavigationBar from "./NavigationBar";
import useToggle from "../../Hooks/useToggle";
import DrawerUI from "./Drawer";
import { useEffect, useState, memo, useContext } from "react";
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import LanguageSwitcher from "./LanguageSwitcher";
import { get_categories } from "../../service/api";
import { CategoriesContext } from "../../context/CategoriesProvider";

export interface Category {
  id: number;
  title: string;
  slug: string;
}

export type HeaderType = {
  className?: string;
  linkText?: string;
  keyRender: string;
  cate?: string;
};

const Header: NextPage<HeaderType> = ({
  className = "",
  linkText = "",
  keyRender = "",
  cate = "",
}) => {
  const [drawer, drawerAction] = useToggle(false) as any;
  const [mouted, setMouted] = useState(false);
  const { t, i18n } = useTranslation('common');
  const context = useContext(CategoriesContext);
  let categories: Category[] = []

  if (context != undefined && context) {
    categories = context.categories;
  }

  useEffect(() => {
    setMouted(true);
  }, []);

  return (
    mouted && (
      <>
        <DrawerUI
          drawer={drawer}
          action={drawerAction.toggle}
          keyRender={keyRender}
          categories={categories}
          linkText={linkText}
        />
        <header
          className={`self-stretch flex flex-col items-start justify-start pt-0 px-0 box-border max-w-full text-left text-xs text-white font-headline ${className}`}
        >
          <div className="self-stretch bg-primary flex flex-row items-center justify-between py-1.5 px-5 box-border shrink-0 max-w-full gap-[20px] mq450:hidden">
            <div className="flex flex-row items-center justify-start gap-[12px] max-w-full">
              <div className="flex flex-row items-center justify-start gap-[4px]">
                <img
                  className="h-4 w-4 relative overflow-hidden shrink-0 min-h-[16px]"
                  loading="lazy"
                  alt=""
                  src="/phone-android.svg"
                />
                <a
                  href="#"
                  className="[text-decoration:none] relative font-light text-[inherit] inline-block min-w-[66px]"
                >
                  +84.911.180.1688
                </a>
              </div>
              <div className="flex flex-row items-center justify-start gap-[4px]">
                <img
                  className="h-4 w-4 relative overflow-hidden shrink-0 min-h-[16px]"
                  alt=""
                  src="/mail.svg"
                />
                <div className="relative font-light inline-block min-w-[108px]">
                  Info@spackplastics.com
                </div>
              </div>
              <div className="flex flex-row items-center justify-start gap-[4px]">
                <img
                  className="h-4 w-4 relative overflow-hidden shrink-0 min-h-[16px]"
                  alt=""
                  src="/word.svg"
                />
                <a
                  href="#"
                  className="[text-decoration:none] relative font-light text-[inherit] inline-block min-w-[82px] whitespace-nowrap"
                >
                  {t('shipping_worldwide')}
                </a>
              </div>
            </div>
            <LanguageSwitcher />
          </div>
          <div className="self-stretch h-[57px] relative shrink-0 max-w-full text-base text-darkslateblue">
            <div className="absolute top-[0px] left-[0px] bg-white w-full flex flex-row items-center justify-between py-2 px-5 box-border gap-[20px] max-w-full h-full">
              <Button
                href={"/"}
                style={{
                  textDecoration: "none",
                  color: "#a4a4a4",
                }}
              >
                <div className="flex flex-row items-center justify-start gap-[4px]">
                  <div className="flex flex-row items-center justify-start gap-[4px]">
                    <img
                      className="h-6 w-[28.5px] relative"
                      loading="lazy"
                      alt=""
                      src="/frame-2.svg"
                    />
                    <div className="w-[57px] relative font-semibold hidden">
                      S-pack
                    </div>
                  </div>
                  <a
                    href={"/"}
                    className="[text-decoration:none] relative font-semibold text-dodgerblue-100 inline-block "
                  >
                    S-pack
                  </a>
                </div>
              </Button>
              <div className="flex flex-1 flex-row items-center justify-end gap-[32px] max-w-full text-text-title mq450:gap-[16px]">
                <NavigationBar customClass={undefined} linkText={linkText} categories={categories} cate={cate}/>
                <div className="w-[400px] mq850:hidden">
                <TextField
                  className="[border:none] bg-[transparent] h-9 flex-1 font-headline font-light text-sm text-darkgray w-full"
                  placeholder={t('search_placeholder')}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <img
                        width="16px"
                        height="16px"
                        src="/search.svg"
                        alt=""
                      />
                    ),
                  }}
                  sx={{
                    "& fieldset": { borderColor: "#9e9e9e" },
                    "& .MuiInputBase-root": {
                      height: "36px",
                      backgroundColor: "#fff",
                      paddingRight: "16px",
                      fontSize: "14px",
                    },
                    "& .MuiInputBase-input": { color: "#000" },
                  }}
                />
                </div>
                <div className="hidden mq450:block">
                  <LanguageSwitcher />
                </div>
                <div
                  onClick={drawerAction.toggle}
                  className="flex flex-row items-center justify-start gap-[4px] lg:hidden"
                >
                  <i className="fa fa-bars" />
                </div>
              </div>
            </div>
            <div className="absolute top-[56px] left-[0px] box-border w-[1920px] h-px border-t-[1px] border-solid border-dodgerblue-100 mq450:hidden" />
          </div>
        </header>
      </>
    )
  );
};

export default memo(Header);
