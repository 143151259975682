import React from "react";
import data from "../../data/navigation.json";
import Drawer from "@mui/material/Drawer";
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  IconButton,
  TextField,
} from "@mui/material";
import { useTranslation } from 'next-i18next';

function DrawerUI({ drawer, linkText, action, keyRender, categories }) {
  const { t } = useTranslation('common');
  const [open, setOpen] = React.useState(false);

  const handleClick = (isChild) => {
    if (!!isChild) {
      setOpen(!open);
    } else {
      setOpen(false);
    }
  };

  return (
    <Drawer open={drawer} onClose={(e) => action(e)} PaperProps={{
      className: "bg-white w-full h-full flex flex-col justify-between font-headline",
    }}>
      <List
        sx={{ bgcolor: "background.paper", padding: '20px' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {/* <div className="flex flex-row items-center justify-start gap-[4px] mb-2">
          <div className="flex flex-row items-center justify-start gap-[4px]">
            <img
              className="h-6 w-[28.5px] relative"
              loading="lazy"
              alt=""
              src="/frame-2.svg"
            />
            <div className="w-[57px] relative font-semibold hidden">{t('drawer.title')}</div>
          </div>
          <a className="[text-decoration:none] relative text-13xl font-semibold text-dodgerblue-100 inline-block min-w-[113px]">
            {t('drawer.title')}
          </a>
        </div> */}
        <div className="flex flex-row items-center justify-end">
          <IconButton
            className="h-18 w-18 hover:bg-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            onClick={(e) => action(e)}
          >
            <img
              className="h-18 w-18 pb-[12px] relative overflow-hidden shrink-0 min-h-[16px]"
              alt=""
              src="/close.svg"
            />
          </IconButton>
        </div>

        <TextField
          className="[border:none] bg-[transparent] flex-1 font-headline font-light text-sm text-darkgray w-full"
          placeholder={t('search_placeholder')}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <img
                width="16px"
                height="16px"
                src="/search.svg"
                alt=""
              />
            ),
          }}
          sx={{
            "& fieldset": { borderColor: "#9e9e9e" },
            "& .MuiInputBase-root": {
              height: "36px",
              backgroundColor: "#fff",
              paddingRight: "16px",
              marginBottom: "16px",
              fontSize: "14px",
            },
            "& .MuiInputBase-input": { color: "#000" },
          }}
        />
        {data && data.data && data.data.length > 0
          ? data.data.map((item, i) => (
            <div key={`drawer${i}${keyRender}`}>
              <ListItemButton
                onClick={() => handleClick(item.child)}
                href={item.child ? "#" : item.link}
                className={`px-[16px] py-[10px] ${linkText === t(item.translationKey) ? '!bg-status-active/10' : ''}`}
              >
                <div className={`font-headline font-medium ${linkText === t(item.translationKey) ? 'text-status-active' : 'text-text-title'}`}>
                  {t(item.translationKey)}
                </div>
                {item.child && categories && (
                  <span className="menu-expand ml-3 mr-1">
                    <i className="fa fa-angle-down"></i>
                  </span>
                )}
              </ListItemButton>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.child && categories &&
                    categories.map((category, j) => (
                      <ListItemButton
                        key={`${keyRender}${j}${i}`}
                        sx={{ pl: 4 }}
                        onClick={() => handleClick(false)}
                        href={`/${category.slug}?cat=${category.id}`}
                      >
                        <div className="font-headline font-medium !text-text-title">
                          {category.title}
                        </div>
                      </ListItemButton>
                    ))}
                </List>
              </Collapse>
            </div>
          ))
          : null}

      </List>
      <div className="px-[20px] py-[16px] flex flex-col items-start justify-start gap-[12px] bg-whitesmoke-100">
        <div className="flex flex-row items-center justify-start gap-[4px]">
          <img
            className="h-4 w-4 relative overflow-hidden shrink-0 min-h-[16px]"
            loading="lazy"
            alt=""
            src="/phone_android_grey.svg"
          />
          <a
            href="#"
            className="[text-decoration:none] relative font-headline text-[inherit] inline-block min-w-[66px] text-sm text-text-content-1"
          >
            +84.911.180.1688
          </a>
        </div>
        <div className="flex flex-row items-center justify-start gap-[4px]">
          <img
            className="h-4 w-4 relative overflow-hidden shrink-0 min-h-[16px]"
            alt=""
            src="/mail_android.svg"
          />
          <div className="relative inline-block font-headline min-w-[108px] text-sm text-text-content-1">
            Info@spackplastics.com
          </div>
        </div>
        <div className="flex flex-row items-center justify-start gap-[4px]">
          <img
            className="h-4 w-4 relative overflow-hidden shrink-0 min-h-[16px]"
            alt=""
            src="/word_android.svg"
          />
          <a
            href="#"
            className="[text-decoration:none] relative font-headline text-[inherit] inline-block min-w-[82px] whitespace-nowrap text-sm text-text-content-1"
          >
            {t('shipping_worldwide')}
          </a>
        </div>
      </div>
    </Drawer>
  );
}

export default DrawerUI;
