import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

const LanguageSwitcher = () => {
  const router = useRouter();
  const { i18n } = useTranslation();

  const changeLanguage = (locale: string) => {
    router.push(router.pathname, router.asPath, { locale });
  };

  return (
    <div className="flex flex-row items-center justify-start gap-[16px]">
    <img
      className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] cursor-pointer"
      alt=""
      onClick={() => changeLanguage('en')}
      src={i18n.language === 'en' ? "/language-1-active.svg" : "/language-1.svg"}
    />
     <img
      className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] cursor-pointer"
      loading="lazy"
      onClick={() => changeLanguage('vi')}
      alt=""
      src={i18n.language === 'vi' ? "/language-active.svg" : "/language.svg"}
    />
  </div>
  );
};

export default LanguageSwitcher;
